<template>
  <div class="connent_title">
    <div class="connent_header">
      <span>错误日志</span>
    </div>
  </div>

  <el-form :inline="true" class="demo-form-inline">
    <el-form-item label="创建日期">
      <el-date-picker
          v-model="searchData.createDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD">
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="success" @click="onSearch">查询</el-button>
    </el-form-item>
  </el-form>

  <Info
      :params="dialog"
      @getTableData="getTableData"
      @onClose="onClose"/>

  <el-table
      :data="tableData"
      v-loading="loading"
      style="width: 100%;border: 1px solid #EBEEF5;border-radius: 5px;">
    <el-table-column
        v-for="item in tableOptions"
        :key="item.value"
        :label="item.label"
        :width="item.width"
        :prop="item.value">
    </el-table-column>

    <el-table-column label="操作" width="120">
      <template #default="scope">
        <el-button
            size="mini"
            type="primary"
            @click="onView(scope.$index, scope.row)">查看
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
      background
      :pager-count="11"
      layout="total, prev, pager, next, jumper"
      :total="pageData.total"
      :page-size="pageData.size"
      :current-page="pageData.current"
      @current-change="changePage">
  </el-pagination>

</template>

<script>

import axios from '@/utils/axios'
import Info from './Info'

export default {
  data() {
    return {
      loading: false,
      dialog: {
        // 是否显示dialog弹出表单
        show: false,
        // dialog标题
        title: "",
        // dialog表单数据
        data: {}
      },
      // 分页信息
      pageData: window.global.config.page,
      // 搜索区内容
      searchData: {
      },
      // 表格设置
      tableOptions: [{
        value: 'message',
        label: '错误内容',
      }, {
        value: 'createdDate',
        width: 210,
        label: '创建时间',
      }],
      // 表格数据
      tableData: []
    }
  },
  components: {
    Info
  },
  created() {
    this.getTableData()
  },
  methods: {
    onSearch() {
      this.getTableData()
    },
    onView(index, row) {
      this.dialog.title = "查看日志";
      this.dialog.data = row;
      this.dialog.show = true;
    },
    onClose() {
      this.dialog.show = false;
    },

    // 获取列表
    getTableData() {
      this.loading = true
      var that = this

      if (this.searchData.createDate){
        var _searchData = {
          startDate: this.searchData.createDate[0]+" 00:00:00",
          stopDate: this.searchData.createDate[1]+" 23:59:59",
        }
      }
      axios.get('/SystemErrorLog/List', {
        params: Object.assign({}, _searchData, this.pageData)
      }).then(res => {
        that.tableData = res.data.list
        that.pageData.total = res.data.total
        that.loading = false
      }).catch(error => {
        that.loading = false
      })
    },

    changePage(page) {
      this.pageData.current = page
      this.getTableData()
    }
  }
}
</script>